import {apiUrl} from '../../../settings'

export const addPaymentUrl = () => {
    return apiUrl + '/api-unizportal/addPayment'
}
export const payPaymentUrl = () => {
    return apiUrl + '/api-unizportal/payPayment'
}
export const singlePaymentUrl = () => {
    return apiUrl + '/api-unizportal/singlePayment'
}

export const payUrl = () => {
    return apiUrl + '/api-unizportal/pay'
}

export const updatePaymentApi = () => {
    return apiUrl + '/api-unizportal/updatePayment'
}
