import {addPaymentUrl, payPaymentUrl, singlePaymentUrl, payUrl, updatePaymentApi} from '../apis'
import {customAxios as axios} from '../../../request'

export const addPaymentFxn = async (valData) => {
    let {data} = await axios.post(addPaymentUrl(), valData)
    return data
}

export const payPaymentFxn = async (valData) => {
    let {data} = await axios.post(payPaymentUrl(), valData)
    return data
}
export const singlePaymentFxn = async (valData) => {
    let {data} = await axios.post(singlePaymentUrl(), valData)
    return data
}

export const payFxn = async (valData) => {
    let {data} = await axios.post(payUrl(), valData)
    return data
}

export const updatePaymentFxn = async (valData) => {
    let {data} = await axios.post(updatePaymentApi(), valData)
    return data
}
